@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
$open: "Open Sans", sans-serif;
$iphone8plus: "max-width: 450px";
$ipad: "max-width:780px";
$display4k: "min-width:1900px";

main{
  background: #F4F9FA;
}
.cardscalculadoras-component {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  @media ($ipad) {
    flex-direction: column;
    height: auto;
    margin-bottom: -2em;
  }
  .cards-component {
    position: relative;
    width: 50%;
    margin: 3%;
    padding: 2% 0;
    background: #ffffff;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    min-height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media ($ipad) {
      width: 100%;
      min-height: auto;
      border-radius: 0px;
    }
    &.first-one {
      margin-right: 1.5%;
      @media ($ipad) {
        margin: 0;
        padding-bottom: 1em;
        border-radius: 30px 30px 0px 0px;
        box-shadow: 0px -5px 10px rgba(159, 159, 159, 0.25);
      }
      h3 {
        margin: 1.8em 0 0.4em 0;
        &.first-text {
          font-family: $open;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          max-width: 83%;
          color: #1a154c;
          @media ($ipad) {
            font-size: 24px;
            line-height: 30px;
            font-weight: 500;
          }
          span {
            &.blue-light {
              color: #049ab3;
            }
          }
        }
      }
      h5 {
        &.calcular-seguridad {
          font-family: $open;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #1a154c;
          margin: 2em 0 2.5em 0;
          @media ($ipad) {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
    &.second-one {
      margin-left: 1.5%;
      @media ($ipad) {
        margin: 0px;
        padding-bottom: 4em;
      }
      .blue-component {
        background: #049ab3;
        border-radius: 10px;
        width: 90%;
        @media ($ipad) {
          background: linear-gradient(180deg, #1a154c 0%, #049ab3 100%), #049ab3;
        }
        .number-component {
          font-family: $open;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 65px;
          text-align: center;
          color: #1a154c;
          @media ($ipad) {
            color: #ffffff;
          }
        }
        p {
          margin: 0.1em 0 1em 0;
          &.ab-mensual {
            font-family: $open;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #1a154c;
            @media ($ipad) {
              color: #ffffff;
            }
          }
        }
      }
      h5 {
        margin: 0;
        &.seguridad-compo {
          font-family: $open;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          color: #1a154c;
          margin: 1em 0 0.2em 0;
          @media ($ipad) {
            font-size: 16px;
            line-height: 18px;
            max-width: 60%;
            margin: 1.3em 0 1em 0;
          }
          span {
            &.blueletra {
              color: #049ab3;
            }
          }
        }
      }
      .icons-component {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 0 0.8em 0;
        width: 100%;
        @media ($ipad) {
          margin: 0;
        }
        .sfdk {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .number-sfdk {
            font-family: $open;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            text-align: center;
            color: #1a154c;
            height: 40px;
            margin: 0 0 0.2em 0;
            @media ($ipad) {
              font-size: 28px;
            }
          }
          .before-img {
            width: 100%;
            &.withborder {
              border-left: 1px solid #1a154c;
            }
            img {
              &.imagenrelativa {
                width: 70px;
                height: 70px;
                @media ($ipad) {
                  width: 60px;
                  height: 60px;
                }
              }
            }
          }
          .text-complement {
            font-family: $open;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: #1a154c;
            margin: 1em 0;
            width: 60%;
            height: 80px;
            @media ($ipad) {
              font-size: 16px;
              line-height: 18px;
              height: 60px;
            }
            .blueelight {
              color: #049ab3;
            }
          }
          &.tres-compo {
            margin-left: 1.5em;
            @media ($ipad) {
              margin: 0;
            }
          }
          &.dos-compo {
            margin-right: 1.5em;
            @media ($ipad) {
              margin: 0;
            }
          }
        }
      }
    }
    button {
      position: absolute;
      bottom: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 40px;
      background: #049ab3;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2),
        0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14);
      border-radius: 100px;
      text-decoration: none;
      border: none;
      margin: 1em 0;
      outline: none;
      cursor: pointer;
      @media ($ipad) {
        position: relative;
        width: 80%;
      }
      &:hover {
        box-shadow: 3px 3px 6px 0px rgb(153, 148, 148);
        transform: translateY(2px);
        transform: translatex(-2px);
        background: linear-gradient(90deg, #1a154c 0%, #049ab3 100%), #049ab3;
      }
      p {
        align-content: center;
        font-family: $open;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
      }
      img {
        margin-left: 1em;
      }
    }
  }
}
