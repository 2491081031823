@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
$open: "Open Sans", sans-serif;
$iphone8plus: "max-width: 450px";
$ipad: "max-width:780px";
$display4k: "min-width:1900px";

body {
  margin: 0;
}
.component-information {
  height: auto;
  padding-bottom: 4em;
  .component-image {
    min-height: 463px;
    background-repeat: no-repeat;
    background: url("../../assets/img/informationfamily.png"), #c4c4c4;
    background-blend-mode: darken;
    background-position: center;
    background-size: cover;
    background-position-y: 65%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 100;
    @media ($display4k) {
      min-height: 663px;
    }
    @media ($iphone8plus) {
      border-radius: 30px;
    }
    h3 {
      position: absolute;
      margin: 0%;
      font-family: $open;
      font-style: bold;
      font-size: 38px;
      line-height: 50px;
      color: #ffffff;
      max-width: 50%;
      @media ($iphone8plus) {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        max-width: 75%;
      }
      @media ($ipad) {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        max-width: 75%;
        top: 10%;
      }
      @media ($display4k) {
        font-style: normal;
        font-weight: 700;
        font-size: 58px;
        line-height: 60px;
      }
    }
  }
  .component-classifications {
    background-color: #ffffff;
    z-index: 1000;
    position: relative;
    @media ($iphone8plus) {
      border-radius: 30px 30px 0px 0px;
      margin-top: -10%;
    }
    .comp-clasi-white {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    p {
      max-width: 80%;
      font-family: $open;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      color: #1a154c;
      margin: 2em 0 0 0;
      @media ($iphone8plus) {
        font-size: 16px;
        line-height: 20px;
        max-width: 85%;
      }
    }
    h5 {
      font-family: $open;
      font-style: normal;
      font-weight: 800;
      font-size: 19px;
      line-height: 30px;
      color: #1a154c;
      max-width: 65%;
      @media ($iphone8plus) {
        max-width: 80%;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin: 2em 0 2em 0;
      }
      .inherit-ss {
        color: #049ab3;
      }
    }
    .components-3cards {
      display: flex;
      width: 95%;
      justify-content: center;
      align-items: flex-start;
      @media ($iphone8plus) {
        flex-direction: column;
        width: 80%;
        text-align: left;
      }
      .card-compo {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media ($iphone8plus) {
          flex-direction: row;
          width: auto;
        }
        img {
          min-width: 60px;
          max-width: 60px;
          width: 60px;
          @media ($ipad) {
            min-width: 55px;
            max-width: 55px;
            width: 55px;
          }
          @media ($iphone8plus) {
            min-width: 23.25px;
            max-width: 23.25px;
            width: 23.25px;
          }
        }

        .co-double {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media ($iphone8plus) {
            align-items: flex-start;
            margin-left: 10px;
          }
          h5 {
            font-family: $open;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #049ab3;
            margin: 30px 0 10px 0;
            @media ($iphone8plus) {
              margin: 10px 0 5px 12px;
              font-size: 18px;
              line-height: 25px;
              max-width: 90%;
            }
          }
          .hola-cali {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
              font-family: $open;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #6e6e6e;
              margin: 0;
              max-width: 60%;
              @media ($iphone8plus) {
                margin: 5px 0 5px 0px;
                font-size: 16px;
                line-height: 20px;
                max-width: 90%;
              }
            }
          }
        }
      }
    }
  }
}
