@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
$open: "Open Sans", sans-serif;
$iphone8plus: "max-width: 450px";
$ipad: "max-width:780px";
$display4k: "min-width:1900px";
.gastos-totales-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  @media ($ipad) {
    height: auto;
    position: relative;
    width: 100%;
  }
  .gastos-totales-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(159, 159, 159, 0.25);
    border-radius: 30px;
    max-width: 61%;
    min-width: 61%;
    @media ($ipad) {
      background: #ffffff00;
      border: none;
      border-radius: 0px;
      box-shadow: none;
      padding: 0;
      width: 100%;
      min-width: 100%;
    }
    .border-component {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-style: dashed;
      border-width: thin;
      border-radius: 30px;
      min-width: 99%;
      @media ($ipad) {
        border-style: none;
        min-width: 100%;
      }
      h4 {
        &.gastos-title {
          font-family: $open;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #1a154c;
          margin: 15px 0px 10px 0;

          @media ($ipad) {
            font-size: 12px;
            line-height: 16px;
            margin: 20px 0px 10px 0;
          }
        }
      }
      .result-component {
        font-family: $open;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 65px;
        text-align: center;
        color: #1a154c;
        @media ($ipad) {
          font-size: 36px;
          line-height: 49px;
          background: #ffffff;
          box-shadow: 0px -5px 10px rgba(159, 159, 159, 0.25);
          border-radius: 5px;
          min-width: 88%;
          padding: 0.7em 0em;
          margin: 0.2em 0em;
        }
      }
      p {
        &.parrafo-tiempo {
          font-family: $open;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #1a154c;
          margin: 5px 0px 20px 0;

          @media ($ipad) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
