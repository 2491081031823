@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
$open: "Open Sans", sans-serif;
$iphone8plus: "max-width: 450px";
$ipad: "max-width:780px";
$display4k: "min-width:1900px";

body {
  margin: 0;
}
.header-container {
  margin-bottom: 4.3em;
  @media ($iphone8plus) {
    margin-bottom: 18.5em;
  }
  .nav-bar-enroque {
    height: 69px;
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ($display4k) {
      min-height: 80px;
    }
    .hea-img-title {
      cursor: pointer;
      &.big {
        display: inline;
        @media ($iphone8plus) {
          display: none;
        }
        @media ($display4k) {
          width: 140%;
        }
      }
      &.mini {
        display: none;
        @media ($iphone8plus) {
          display: inline;
        }
      }
    }
  }
  .header-enroque {
    position: relative;
    z-index: 9000;
    min-height: 90vh;
    top: 69px;
    background-image: url("../../assets/img/headerfamily.png");
    background-size: cover;
    background-position-y: 30%;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    @media ($iphone8plus) {
      background-position-x: 30%;
      min-height: 60vh;
    }
    .header-box {
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        ),
        rgba(255, 255, 255, 0.6);
      box-shadow: 0px -5px 10px rgba(159, 159, 159, 0.25);
      border-radius: 30px 30px 0px 0px;
      position: absolute;
      bottom: 0%;
      max-width: 50%;
      padding: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media ($iphone8plus) {
        bottom: -46%;
        top: 65%;
        background-color: #ffffff;
        min-width: 82%;
      }
      @media ($ipad) {
        min-width: 80%;
      }
      h3 {
        font-family: $open;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #1a154c;
        margin-top: 0.2em;
        margin-bottom: 0.5em;
        @media ($ipad) {
          font-size: 16px;
          line-height: 20px;
        }
        @media ($iphone8plus) {
          color: #049ab3;
          max-width: 70%;
        }
      }
      h1 {
        font-family: $open;
        font-style: normal;
        font-weight: 800;
        font-size: 38px;
        line-height: 40px;
        color: #1a154c;
        margin-top: 0.1em;
        margin-bottom: 0.5em;
        @media ($ipad) {
          font-size: 24px;
          line-height: 30px;
        }
        @media ($iphone8plus) {
          max-width: 99%;
        }
      }
      h4 {
        max-width: 68%;
        font-family: $open;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #049ab3;
        margin-bottom: 0.2em;
        margin-top: 0.1em;
        @media ($ipad) {
          font-size: 14px;
          line-height: 20px;
        }
        @media ($iphone8plus) {
          max-width: 85%;
        }
      }
    }
  }
}
