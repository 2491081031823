@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
$open: "Open Sans", sans-serif;
$quick: "Quicksand", sans-serif;
$iphone8plus: "max-width: 450px";
$ipad: "max-width:780px";
$display4k: "min-width:1900px";

body {
  margin: 0;
}

.footer-white {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  h4 {
    max-width: 35%;
    text-align: center;
    font-family: $open;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #1a154c;
    @media ($iphone8plus) {
      font-size: 16px;
      line-height: 20px;
      max-width: 60%;
    }
    @media ($ipad) {
      max-width: 70%;
    }
  }
  a {
    button {
      &.footer-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 10px 12px 10px;
        background: #049ab3;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2),
          0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14);
        border-radius: 100px;
        min-width: 400px;
        margin: 1.5em 0 1.5em 0;
        border: none;
        cursor: pointer;
        @media ($iphone8plus) {
          padding: 12px 5px 12px 5px;
          min-width: 300px;
        }
        p {
          font-family: $quick;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 1.25px;
          color: #ffffff;
          margin: 0;
          padding: 0;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          img {
            margin-left: 0.5em;
            .fo-svg {
              color: #ffffff;
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
      &:focus {
        outline: none;
      }
    }
    &:focus {
      outline: none;
    }
    text-decoration: none;
  }
  img {
    &.fo-svg-lo {
      margin-top: 3em;
      max-height: 120px;
      @media ($ipad) {
        max-height: 80px;
      }
    }
  }
  padding: 4em 0 4em 0;
}
